import { ThunkAction } from 'components/common/AppProvider';
import { FinancialRequestsApi } from 'services/ApiService';
import { SaveFinancialRequest, SaveFinancialResponse } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import {
    createClearFilesAction,
    createFetchFiancialRequestByIdAction,
    createFetchFiancialRequestByIdFailureAction,
    createFetchFiancialRequestByIdSuccessAction,
    createFetchFiancialRequestCountAction,
    createFetchFiancialRequestCountFailureAction,
    createFetchFiancialRequestCountSuccessAction,
    createFetchRightToSeeFinancialRequestsAction,
    createFetchRightToSeeFinancialRequestsFailureAction,
    createFetchRightToSeeFinancialRequestsSuccessAction,
    createSearchFinancialRequestAction,
    createSearchFinancialRequestFailureAction,
    createSearchFinancialRequestSuccessAction,
    createSubmitFinancialAction,
    createSubmitFinancialFailureAction,
    createSubmitFinancialSuccessAction,
} from './FinancialAction';
import { abortRequests } from 'services/ApiService/Http';

export const submitFinancialRequest = (siren: number, request: SaveFinancialRequest)
    : ThunkAction<Promise<SaveFinancialResponse>> => async (dispatch, getState) => {
        const state = getState();

        if (state.financial[siren]?.financialRequest?.isFetching) {
            const promise = PromiseStore.get<SaveFinancialResponse>('submitFinancialRequest', request);
            if (promise) {
                return await promise;
            }
        }

        try {
            const fetchTask = (async () => {
                const data = await FinancialRequestsApi.submitFinancialRequest(siren, request);
                state.financial[siren]?.files.forEach(async file => {
                    if (data.requestId) {
                        await FinancialRequestsApi.uploadFile(file.type, data.requestId, file.file);
                    }
                });
                dispatch(createSubmitFinancialSuccessAction(siren));
                dispatch(createClearFilesAction(siren));

                return data;
            })();

            PromiseStore.set(fetchTask, 'submitFinancialRequest', request);

            dispatch(createSubmitFinancialAction(siren));

            return await fetchTask;
        }
        catch (error) {
            dispatch(createSubmitFinancialFailureAction(siren));
            logError(error);
            throw error;
        }
    };

export const getFinancialRequest = (siren: number, requestId: number)
    : ThunkAction<Promise<void>> => async (dispatch, getState) => {
        const state = getState();

        if (state.financial[siren]?.validatedFinancialRequest?.[requestId]?.isFetching
            || !state.financial[siren]?.validatedFinancialRequest?.[requestId]?.didInvalidate
        ) {
            const promise = PromiseStore.get('getFinancialRequest', requestId);
            if (promise) {
                return await promise;
            }
        }

        try {
            const fetchTask = (async () => {
                const data = await FinancialRequestsApi.getFinancialRequest(siren, requestId);
                dispatch(createFetchFiancialRequestByIdSuccessAction(siren, requestId, data));
            })();

            PromiseStore.set(fetchTask, 'getFinancialRequest', requestId);
            dispatch(createFetchFiancialRequestByIdAction(siren, requestId));

            return await fetchTask;
        }
        catch (error) {
            dispatch(createFetchFiancialRequestByIdFailureAction(siren, requestId));
            logError(error);
            throw error;
        }
    };

export const getFinancialRequestCount = (siren: number)
    : ThunkAction<Promise<void>> => async (dispatch, getState) => {
        const state = getState();

        if (state.financial[siren]?.count?.isFetching
            || !state.financial[siren]?.count?.didInvalidate
        ) {
            const promise = PromiseStore.get('getFinancialRequestCount');
            if (promise) {
                return await promise;
            }
        }

        try {
            const fetchTask = (async () => {
                const data = await FinancialRequestsApi.getFinancialRequestsCount(siren);
                dispatch(createFetchFiancialRequestCountSuccessAction(siren, data));
            })();

            PromiseStore.set(fetchTask, 'getFinancialRequestCount');
            dispatch(createFetchFiancialRequestCountAction(siren));

            return await fetchTask;
        }
        catch (error) {
            dispatch(createFetchFiancialRequestCountFailureAction(siren));
            logError(error);
            throw error;
        }
    };

export const searchFinancialRequests = (siren: number): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    if (state.financial[siren]?.search.isFetching || !state.financial[siren]?.search.didInvalidate) {
        const promise = PromiseStore.get<void>('searchFinancialRequests',
            state.financial[siren]?.search.filters.skip,
            state.financial[siren]?.search.filters.take,
            state.financial[siren]?.search.filters.sortDirection,
            state.financial[siren]?.search.filters.sortSource);
        if (promise) {
            return await promise;
        }
    }

    try {
        abortRequests('/api/v1/financial-requests', 'GET');

        const fetchTask = (async () => {
            const data = await FinancialRequestsApi.getFinancialRequests(
                siren,
                state.financial[siren]?.search.filters.skip,
                state.financial[siren]?.search.filters.take,
                state.financial[siren]?.search.filters.sortDirection,
                state.financial[siren]?.search.filters.sortSource,
            );
            dispatch(createSearchFinancialRequestSuccessAction(siren, data.results || [], data.totalResults || 0));
        })();

        PromiseStore.set(fetchTask, 'searchFinancialRequests',
            state.financial[siren]?.search.filters.skip,
            state.financial[siren]?.search.filters.take,
            state.financial[siren]?.search.filters.sortDirection,
            state.financial[siren]?.search.filters.sortSource);

        dispatch(createSearchFinancialRequestAction(siren));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createSearchFinancialRequestFailureAction(siren));
        logError(error);
        throw error;
    }
};

export const getRightToSeeFinancialRequests = (siren: number): ThunkAction<Promise<boolean>> => async (dispatch, getState) => {
    const state = getState();

    if (state.financial[siren]?.canSeeFinancialRequests?.isFetching || !state.financial[siren]?.canSeeFinancialRequests?.didInvalidate) {
        const promise = PromiseStore.get<boolean>('getRightToSeeFinancialRequests', siren);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await FinancialRequestsApi.hasRightToSeeFinancialRequests(siren);
            dispatch(createFetchRightToSeeFinancialRequestsSuccessAction(siren, data.canSeeFinancialRequest));
            return data.canSeeFinancialRequest;
        })();

        PromiseStore.set(fetchTask, 'getRightToSeeFinancialRequests', siren);
        dispatch(createFetchRightToSeeFinancialRequestsAction(siren));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchRightToSeeFinancialRequestsFailureAction(siren));
        logError(error);
        throw error;
    }
};
