import { useAppContext } from 'components/common/AppProvider';
import { ServiceLoader } from 'components/common/Loading';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasAccess } from 'services/RightsService';
import { sgConnect, redirectUnauthorizedUser } from 'services/SgConnect';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';
import { RoutePaths } from './RoutePaths';
import { Environment, getEnvironment } from 'config/constants';
import { getRightToSeeFinancialRequests } from 'store/Financial/FinancialThunk';

export const ProtectedRoute: React.FC<{ children: JSX.Element, CheckFranfinanceAuthorization?: boolean, hideInProduction?: boolean }> = ({ children, CheckFranfinanceAuthorization = false, hideInProduction = false }) => {
    const { dispatch, state: { appContext: { loggedUserId, isFetching, currentClient: { siren } }, financial, entities } } = useAppContext();
    const navigate = useNavigate();
    const loggedUser = FranfinanceSelectors.getLoggedUser(loggedUserId, entities.franFinance);
    const hide = hideInProduction ? getEnvironment() === Environment.Production && hideInProduction : false;

    useEffect(() => {
        (async () => {
            if (!sgConnect?.isAuthorized()) {
                sgConnect?.requestAuthorization();
            }
            else {
                const appContext = await dispatch(getAppContext());
                const canSeeFinancialRequests = siren && await dispatch(getRightToSeeFinancialRequests(siren));
                if (hide) {
                    navigate(RoutePaths.Errors.NotFound.url());
                }

                if (!hasAccess(appContext?.loggedUser)) {
                    redirectUnauthorizedUser();
                }

                if (CheckFranfinanceAuthorization && siren && !financial[siren]?.canSeeFinancialRequests?.isFetching && !canSeeFinancialRequests) {
                    navigate(RoutePaths.Errors.NotAuthorized.url());
                }
            }
        })();
    });

    if (isFetching) {
        return <div className="d-flex justify-content-center"><ServiceLoader /></div>;
    }
    
    return hasAccess(loggedUser) ? children : null;
};
